<template>
  <b-table
    :data="medalStanding.russia"
    :loading="isLoading"
    :mobile-cards="false"
  >
    <b-table-column v-slot="props" label="Вид спорта">
      {{ props.row.sport.Title }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Золото"
      numeric
      centered
      :subheading="medalStanding.firstPlace"
    >
      {{ props.row.first }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Серебро"
      numeric
      centered
      :subheading="medalStanding.secondPlace"
    >
      {{ props.row.second }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Бронза"
      numeric
      centered
      :subheading="medalStanding.thirdPlace"
    >
      {{ props.row.third }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Всего"
      numeric
      centered
      :subheading="allMedals"
    >
      {{ +props.row.first + +props.row.third + +props.row.second }}
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: "MedalStandingRussiaTable",

  props: {
    medalStanding: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    allMedals() {
      const { firstPlace, secondPlace, thirdPlace } = this.medalStanding;
      return +firstPlace + +secondPlace + +thirdPlace;
    },
  },
};
</script>
