<template>
  <div class="content">
    <b-loading v-if="isLoading" />

    <template v-else>
      <b-tabs v-model="activeTab" type="" :animated="false">
        <b-tab-item label="Россия">
          <AddButton v-if="isShowAdd" :is-icon="true" @click="handleEdit" />

          <div v-else-if="isEditor" class="buttons">
            <b-button type="is-info" icon-right="pencil" @click="handleEdit" />
          </div>

          <h1 class="title is-size-5">
            Общекомандное место:
            <span class="is-size-4 has-text-info-dark ml-2">{{
              medalStanding.teamPlace
            }}</span>
          </h1>

          <MedalStandingRussiaTable
            :is-loading="isLoading"
            :medal-standing="medalStanding"
          />
        </b-tab-item>

        <b-tab-item label="Страны">
          <div v-if="isEditor" class="buttons">
            <b-button
              type="is-info"
              icon-right="pencil"
              @click="handleEditCountries"
            />
          </div>

          <MedalStandingCountriesTable
            :is-loading="isLoading"
            :countries="medalStanding.countries"
          />
        </b-tab-item>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import { Resource } from "@/common/const/common";
import { isEmpty } from "lodash";
import AddButton from "@/common/components/AddButton";
import { mapGetters } from "vuex";
import { NETWORK_ERROR } from "@/common/const/message";
import MedalStandingCountriesForm from "@/modules/calendar/components/MedalStandingCountriesForm";
import MedalStandingRussiaForm from "@/modules/calendar/components/MedalStandingRussiaForm";
import MedalStandingCountriesTable from "@/modules/calendar/components/MedalStandingCountriesTable";
import MedalStandingRussiaTable from "@/modules/calendar/components/MedalStandingRussiaTable";

export default {
  name: "MedalStanding",
  components: {
    MedalStandingRussiaTable,
    MedalStandingCountriesTable,
    AddButton,
  },
  props: {
    sportEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      medalStanding: {},
      isLoading: true,
      activeTab: 0,
    };
  },

  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
    isShowAdd() {
      return this.medalStanding.russia
        ? isEmpty(this.medalStanding.russia)
        : true;
    },
    // allMedals() {
    //   const { firstPlace, secondPlace, thirdPlace } = this.medalStanding;
    //   return +firstPlace + +secondPlace + +thirdPlace;
    // },
  },

  async mounted() {
    try {
      this.medalStanding = await this.$api[Resource.SPORT_EVENTS].get(
        `${this.sportEvent.id}/medal-standing?data=true`
      );

      if (this.isShowAdd) {
        const sports = await this.$api[Resource.SPORTS].get(`?_sort=Title:asc`);
        const data = [];
        sports.forEach(({ Title, Slug, id }) => {
          data.push({
            sport: { id, Title, Slug },
            first: 0,
            second: 0,
            third: 0,
          });
        });

        this.medalStanding.russia = data;
      }

      if (
        !this.medalStanding.countries ||
        isEmpty(this.medalStanding.countries)
      ) {
        await this.setCountries();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleEdit() {
      this.$buefy.modal.open({
        parent: this,
        component: MedalStandingRussiaForm,
        props: { medalStanding: this.medalStanding },
        events: { close: this.handleClose },
        hasModalCard: true,
        fullScreen: false,
      });
    },

    async handleEditCountries() {
      this.$buefy.modal.open({
        parent: this,
        component: MedalStandingCountriesForm,
        props: {
          medalStanding: this.medalStanding,
        },
        events: { close: this.handleClose },
        hasModalCard: true,
        fullScreen: false,
      });
    },

    async setCountries() {
      try {
        const countries = await this.$api.fetchData.get(
          `${Resource.COUNTRIES}?_limit=500&_sort=name:asc`
        );

        this.medalStanding.countries = countries.map(
          ({ id, name, code, alpha2 }) => {
            return {
              country: { id, name, code, alpha2 },
              firstPlace: 0,
              secondPlace: 0,
              thirdPlace: 0,
              teamPlace: 0,
            };
          }
        );
      } catch (e) {
        console.log(e);
        this.$notifier.error(NETWORK_ERROR);
      }
    },

    async handleClose(medalStanding) {
      if (medalStanding) {
        this.medalStanding = medalStanding;
      }
    },
  },
};
</script>
