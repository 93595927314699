<template>
  <div>
    <AddButton
      :is-icon="true"
      @click="handleClickEdit"
      title="Добавить результат"
    />

    <b-table
      :data="results"
      :loading="loading"
      :mobile-cards="false"
      :paginated="isPaginated"
      :pagination-simple="false"
      pagination-position="bottom"
      backend-pagination
      backend-filtering
      backend-sorting
      :total="total"
      :per-page="perPage"
      aria-page-label="Page"
      aria-current-label="Current page"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :debounce-search="500"
      @page-change="onPageChange"
      @sort="onSort"
      @filters-change="onFiltersChange"
    >
      <b-table-column v-slot="props" label="№">
        {{ props.index + 1 + perPage * (page - 1) }}
      </b-table-column>

      <b-table-column label="Дата" field="date" sortable searchable>
        <template #searchable="props">
          <b-datepicker
            v-model="props.filters[props.column.field]"
            :locale="locale"
            icon="calendar-today"
            :icon-right="
              props.filters[props.column.field] ? 'close-circle' : ''
            "
            icon-right-clickable
            @icon-right-click="props.filters[props.column.field] = null"
            :min-date="minDate"
            :max-date="maxDate"
          />
        </template>
        <template v-slot="props">
          {{ props.row.date | date }}
        </template>
      </b-table-column>

      <b-table-column
        :visible="isShowSport"
        v-slot="props"
        label="Спорт"
        field="category_sport.Title"
        sortable
        searchable
      >
        {{
          props.row.category_sport ? props.row.category_sport.Title : "undef"
        }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Дисциплина"
        field="sport_discipline.name"
        sortable
        searchable
      >
        {{ props.row.sport_discipline.name }}
        <span v-if="props.row.round">{{ props.row.round }}</span>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Пол"
        field="sport_discipline.gender"
        sortable
        searchable
      >
        {{ props.row.sport_discipline.gender }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Место"
        field="place"
        sortable
        searchable
        numeric
        min-value="0"
      >
        {{ props.row.place || "" }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Описание"
        field="description"
        searchable
      >
        {{ props.row.description || "" }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Спортсмен"
        field="athletes.lastname"
        searchable
      >
        <template v-if="props.row.athletes.length">
          <router-link
            v-for="(athlete, index) in props.row.athletes"
            :key="athlete.id"
            :to="getLinkToAthlete(athlete)"
            target="_blank"
          >
            {{ `${athlete.lastname} ${athlete.firstname.charAt(0)}.` }}
            <template v-if="index < props.row.athletes.length">
              <br />
            </template>
          </router-link>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Результат"
        field="result"
        searchable
      >
        {{ props.row.result || "" }}
      </b-table-column>

      <b-table-column v-if="isEditor" v-slot="props" label="Действия">
        <div class="buttons">
          <b-button
            type="is-success"
            icon-left="pencil"
            size="is-small"
            @click="handleClickEdit(props.row)"
          />
          <b-button
            type="is-danger"
            icon-left="delete"
            size="is-small"
            @click="handleDelete(props.row.id)"
          />
        </div>
      </b-table-column>

      <template #empty>
        <p v-if="dataFilters.length">
          С текущими фильтрами результаты не найдены
        </p>
        <p v-else>Результатов ещё нет</p>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOCALE, Resource } from "@/common/const/common";
import { defaultSportResult, RESULTS_PER_PAGE } from "../const";
import { getResultsQueryFilters } from "../utils";
import AddButton from "@/common/components/AddButton";
import SportResultForm from "./SportResultForm";
import { DELETE_ERROR, DELETE_SUCCESS } from "@/common/const/message";
import { AppRoute } from "@/common/const/route";

export default {
  name: "SportResultsTable",
  components: { AddButton },
  filters: {
    discipline: function (value) {
      if (!value) {
        return "";
      }
      const { name, gender } = value;
      let output = "";
      if (name) {
        output = name;
      }
      if (gender) {
        output = output.length ? `${output}, ${gender}` : gender;
      }
      return output;
    },
    sport: function (value) {
      return value?.category_sport ? value.category_sport.Title || "" : "";
    },
  },
  props: {
    sportEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      results: [],
      loading: true,
      isPaginated: true,
      perPage: RESULTS_PER_PAGE,
      total: 0,
      defaultSortOrder: "asc",
      page: 1,
      sortField: "date",
      sortOrder: "asc",
      locale: LOCALE,

      dataFilters: [],
      minDate: null,
      maxDate: null,
    };
  },
  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
    isShowSport() {
      const { category_sports: sports } = this.sportEvent;
      return sports.length === 0 || sports.length > 1;
    },
  },
  created() {
    this.minDate = this.$dayjs(this.sportEvent.DateStart).toDate();
    this.maxDate = this.$dayjs(this.sportEvent.DateFinish).toDate();
  },
  async mounted() {
    await this.loadAsyncData();
  },
  methods: {
    printDiscipline(row) {
      const name = row.sport_discipline.name || null;
      const round = props.row.round || "";
      if (name && round) {
        return `${name}. ${round}`;
      } else if (name) {
        return name;
      }

      return round;
    },
    getLinkToAthlete({ id }) {
      return `${AppRoute.ATHLETES}/${id}`;
    },
    async handleClose(isSaved) {
      if (isSaved) {
        await this.loadAsyncData();
      }
    },
    async handleDelete(id) {
      this.$buefy.dialog.confirm({
        title: "Удаление результата",
        class: "is-size-4",
        message: "Результат будет удалён. Операция не обратима!!!",
        cancelText: "Отмена",
        confirmText: "Удалить",
        hasIcon: true,
        type: "is-danger",
        onConfirm: async () => {
          try {
            await this.$api[Resource.SPORT_RESULTS].delete(id);
            await this.loadAsyncData();
            this.$notifier.success(DELETE_SUCCESS);
          } catch (e) {
            this.$notifier.error(DELETE_ERROR);
          }
        },
      });
    },
    async handleClickEdit(sportResult = null) {
      this.$buefy.modal.open({
        parent: this,
        component: SportResultForm,
        props: {
          sportEvent: this.sportEvent,
          sportResult: sportResult ? sportResult : defaultSportResult,
        },
        events: { close: this.handleClose },
        hasModalCard: true,
        // fullScreen: true,
      });
    },
    async loadAsyncData() {
      const start = (this.page - 1) * this.perPage;

      const params = [
        `find-to-local?event=${this.sportEvent.id}`,
        `_sort=${this.sortField}:${this.sortOrder}`,
        `_limit=${this.perPage}`,
        `_start=${start}`,
        ...this.dataFilters,
      ].join("&");

      const countQuery = [
        `count?event=${this.sportEvent.id}`,
        ...this.dataFilters,
      ].join("&");

      this.loading = true;

      try {
        this.results = await this.$api[Resource.SPORT_RESULTS].get(params);
        this.loading = false;
        this.total = await this.$api[Resource.SPORT_RESULTS].get(countQuery);
      } catch (e) {
        this.results = [];
        this.total = 0;
        this.loading = false;
      }
    },
    async onFiltersChange(filters) {
      this.dataFilters = await getResultsQueryFilters(filters, this.$dayjs);
      await this.loadAsyncData();
    },
    async onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      await this.loadAsyncData();
    },
    async onPageChange(page) {
      this.page = page;
      await this.loadAsyncData();
    },
  },
};
</script>
