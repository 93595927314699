<template>
  <section>
    <b-field>
      <template #label>
        <b-button icon-right="close-circle-outline" @click="$emit('add')">
          Поиск по фамилии
        </b-button>
      </template>
      <b-autocomplete
        clearable
        clear-on-select
        :data="data"
        placeholder="Иванов"
        field="lastname"
        :loading="isFetching"
        open-on-focus
        @typing="getAsyncData"
        @select="add"
      >
        <template slot-scope="props">
          <div class="media">
            <div class="media-left">
              <img width="64" :src="getThumbUrl(props.option.photo)" />
            </div>
            <div class="media-content">
              {{ props.option.fullName }}
              &nbsp;
              <small>
                Дата рождения:
                {{ new Date(props.option.birthDate).toLocaleDateString() }}
              </small>
              <br />
              <small>
                Вид спорта: {{ props.option.category_sport.Title }}
              </small>
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import { getThumbUrl } from "@/common/utils/common";
import { Resource } from "@/common/const/common";

export default {
  name: "AddTeamMember",
  props: {
    sportEvent: {
      type: Object,
      required: true,
    },
    athletes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      selected: null,
      isFetching: false,
    };
  },
  computed: {
    ...mapGetters({
      sportType: "auth/getUserSportType",
    }),
    querySports() {
      return this.sportEvent.category_sports.length
        ? this.sportEvent.category_sports
            .map(({ id }) => `category_sport.id_in=${id}`)
            .join("&")
        : `category_sport.category_sport_type=${this.sportType}`;
    },
  },
  methods: {
    getThumbUrl,
    add(options) {
      this.$emit("add", options);
      this.selected = null;
      this.data = [];
    },
    getAsyncData: debounce(async function (name) {
      if (name.length <= 2) {
        this.data = [];
        return;
      }
      this.isFetching = true;

      const uri = "find-athletes";
      const query = `lastname_contains=${name}`;

      // фильтр по видам спорта или типу спорта
      // const url = this.sportType
      //   ? `${uri}?category_sport.category_sport_type=${this.sportType}&${query}`
      //   : `${uri}?${query}`;

      const url = this.sportType
        ? `${uri}?${this.querySports}&${query}`
        : `${uri}?${query}`;

      const body = this.athletes.length
        ? { id_nin: this.athletes.map(({ id }) => id) }
        : {};

      try {
        this.data = await this.$api[Resource.PEOPLES].post(body, url);
      } catch (e) {
        this.data = [];
      } finally {
        this.isFetching = false;
      }
    }, 500),
  },
};
</script>
