<template>
  <form @submit.prevent="handleSubmit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ edited.id ? "Редактировать " : "Новый " }}медальный зачет
        </p>
      </header>

      <section class="modal-card-body">
        <div class="column is-half-desktop">
          <b-field label="Общекомандный зачет" horizontal>
            <b-input
              min="0"
              maxlength="3"
              v-model="edited.teamPlace"
              type="number"
              style="width: 30%"
            />
          </b-field>
        </div>

        <b-table
          :data="edited.russia"
          :loading="isLoading"
          :mobile-cards="false"
        >
          <b-table-column v-slot="props" label="Вид спорта">
            {{ props.row.sport.Title }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            label="Золото"
            width="10%"
            numeric
            centered
            :subheading="countFirst"
          >
            <b-field>
              <b-input min="0" v-model="props.row.first" type="number" />
            </b-field>
          </b-table-column>
          <b-table-column
            v-slot="props"
            label="Серебро"
            width="10%"
            numeric
            centered
            :subheading="countSecond"
          >
            <b-field>
              <b-input min="0" v-model="props.row.second" type="number" />
            </b-field>
          </b-table-column>
          <b-table-column
            v-slot="props"
            label="Бронза"
            width="10%"
            numeric
            centered
            :subheading="countThird"
          >
            <b-field>
              <b-input min="0" v-model="props.row.third" type="number" />
            </b-field>
          </b-table-column>
          <b-table-column
            v-slot="props"
            label="Всего"
            numeric
            centered
            width="10%"
          >
            {{ allInRow(props.row) }}
          </b-table-column>
        </b-table>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-loading="isLoading"
          :is-show-reset="false"
          @send="handleSubmit"
          @close="$emit('close')"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import AppFormButtons from "@/common/components/AppFormButtons";
import { SAVE_ERROR, SAVE_SUCCESS } from "@/common/const/message";
import { Resource } from "@/common/const/common";

export default {
  name: "MedalStandingRussiaForm",

  components: { AppFormButtons },

  props: {
    medalStanding: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      edited: null,
      isLoading: false,
      teamPlace: null,
    };
  },

  computed: {
    countFirst() {
      return this.medalStanding.russia.reduce(
        (acc, { first }) => acc + +first,
        0
      );
    },
    countSecond() {
      return this.medalStanding.russia.reduce(
        (acc, { second }) => acc + +second,
        0
      );
    },
    countThird() {
      return this.medalStanding.russia.reduce(
        (acc, { third }) => acc + +third,
        0
      );
    },
  },

  created() {
    this.edited = { ...this.medalStanding };
    this.teamPlace = this.medalStanding.teamPlace;
  },

  methods: {
    async handleSubmit() {
      this.isLoading = true;

      this.edited.firstPlace = this.countFirst;
      this.edited.secondPlace = this.countSecond;
      this.edited.thirdPlace = this.countThird;

      const russia = this.edited.countries?.find(({ country }) =>
        country.name.includes("Россия")
      );
      if (russia) {
        russia.firstPlace = this.edited.firstPlace;
        russia.secondPlace = this.edited.secondPlace;
        russia.thirdPlace = this.edited.thirdPlace;
        russia.teamPlace = this.edited.teamPlace;
      }

      try {
        const medalStanding = await this.$api[Resource.MEDAL_STANDING].put(
          this.edited,
          this.edited.id
        );

        this.$emit("close", medalStanding);
        this.$notifier.success(SAVE_SUCCESS);
      } catch (e) {
        this.$notifier.error(SAVE_ERROR);
      } finally {
        this.isLoading = false;
      }
    },

    allInRow({ first, second, third }) {
      return +first + +third + +second;
    },
  },
};
</script>
