<template>
  <form @submit.prevent="handleSubmit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Медальный зачет по странам</p>
      </header>

      <section class="modal-card-body">
        <template>
          <b-field v-if="isAdd" label="Добавить страну">
            <b-select placeholder="Добавить страну" @input="handleAddCountry">
              <option
                v-for="country in diffCountries"
                :key="country.id"
                :value="country"
              >
                {{ country.name }}
              </option>
            </b-select>
          </b-field>

          <AddButton
            v-else
            :is-icon="false"
            :is-show-tooltip="false"
            @click="handleAdd"
            title="Добавить страну"
          />
        </template>

        <b-table
          :data="edited"
          :loading="isLoading"
          :mobile-cards="false"
          :row-class="getRowClass"
        >
          <b-table-column
            v-slot="props"
            label="Страна"
            sortable
            field="country.name"
          >
            {{ props.row.country.name }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            label="Золото"
            width="10%"
            numeric
            centered
            sortable
            field="firstPlace"
          >
            <b-field v-if="isEditable(props)">
              <b-input min="0" v-model="props.row.firstPlace" type="number" />
            </b-field>
            <template v-else>
              {{ props.row.firstPlace }}
            </template>
          </b-table-column>
          <b-table-column
            v-slot="props"
            label="Серебро"
            width="10%"
            numeric
            centered
            sortable
            field="secondPlace"
          >
            <b-field v-if="isEditable(props)">
              <b-input min="0" v-model="props.row.secondPlace" type="number" />
            </b-field>
            <template v-else>
              {{ props.row.secondPlace }}
            </template>
          </b-table-column>
          <b-table-column
            v-slot="props"
            label="Бронза"
            width="10%"
            numeric
            centered
            sortable
            field="thirdPlace"
          >
            <b-field v-if="isEditable(props)">
              <b-input min="0" v-model="props.row.thirdPlace" type="number" />
            </b-field>
            <template v-else>
              {{ props.row.thirdPlace }}
            </template>
          </b-table-column>
          <b-table-column
            v-slot="props"
            label="Общекомандный зачет"
            numeric
            centered
            sortable
            field="teamPlace"
          >
            <b-field v-if="isEditable(props)">
              <b-input min="0" v-model="props.row.teamPlace" type="number" />
            </b-field>
            <template v-else>
              {{ props.row.teamPlace }}
            </template>
          </b-table-column>

          <b-table-column v-slot="props" label="">
            <div v-if="isEditable(props)" class="buttons">
              <b-button
                type="is-danger"
                icon-left="delete"
                size="is-small"
                @click="handleDeleteCountry(props.row.country.id)"
              />
            </div>
          </b-table-column>
        </b-table>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-loading="isLoading"
          :is-show-reset="false"
          @send="handleSubmit"
          @close="$emit('close')"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import AppFormButtons from "@/common/components/AppFormButtons";
import { SAVE_ERROR, SAVE_SUCCESS } from "@/common/const/message";
import { Resource } from "@/common/const/common";
import {
  getRowClass,
  sortFirstPlace,
  sortSecondPlace,
  sortTeamPlace,
  sortThirdPlace,
} from "@/modules/calendar/utils";
import AddButton from "@/common/components/AddButton";
import { differenceBy } from "lodash";

export default {
  name: "MedalStandingCountriesForm",

  components: { AddButton, AppFormButtons },

  props: {
    medalStanding: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      edited: null,
      isLoading: false,
      isAdd: false,

      allCountries: [],
      diffCountries: [],
      isDataLoading: false,
    };
  },

  created() {
    this.edited = [...this.medalStanding.countries];
  },

  async beforeDestroy() {
    this.edited = null;
    this.allCountries = null;
    this.diffCountries = null;
  },

  methods: {
    async handleSubmit() {
      this.isLoading = true;

      const russia = this.edited.find((item) =>
        item.country.name.includes("Россия")
      );

      const edited = {
        countries: this.edited,
      };

      if (russia) {
        edited.teamPlace = russia.teamPlace;
      }

      try {
        const medalStanding = await this.$api[Resource.MEDAL_STANDING].put(
          edited,
          this.medalStanding.id
        );

        this.$emit("close", medalStanding);
        this.$notifier.success(SAVE_SUCCESS);
      } catch (e) {
        this.$notifier.error(SAVE_ERROR);
      } finally {
        this.isLoading = false;
      }
    },

    async handleDeleteCountry(id) {
      this.edited = this.edited.filter(({ country }) => country.id !== id);
    },

    async handleAdd() {
      this.isDataLoading = true;
      this.isAdd = true;

      try {
        if (!this.allCountries.length) {
          this.allCountries = await this.$api.fetchData.get(
            `${Resource.COUNTRIES}?_limit=500&_sort=name:asc`
          );
        }

        this.setDiffCountries();
      } catch (e) {
      } finally {
        this.isDataLoading = false;
      }
    },

    async handleAddCountry(country) {
      const item = {
        country,
        firstPlace: 0,
        secondPlace: 0,
        thirdPlace: 0,
        teamPlace: 0,
      };

      this.edited = [...this.edited, item].sort((a, b) => {
        return a.country.name.localeCompare(b.country.name);
      });

      this.isAdd = false;
      this.newCountry = null;
    },

    isEditable({ row }) {
      return !row.country.name.includes("Россия");
    },

    setDiffCountries() {
      const countries = this.edited.map(({ country }) => country);

      this.diffCountries = differenceBy(this.allCountries, countries, "id");
    },

    getRowClass,
    sortFirstPlace,
    sortSecondPlace,
    sortThirdPlace,
    sortTeamPlace,
  },
};
</script>
