<template>
  <div>
    <template>
      <AddTeamMember
        v-if="isAdd"
        :athletes="athletes"
        :sport-event="sportEvent"
        @add="handleAdd"
      />
      <AddButton
        v-else
        :is-icon="true"
        :is-show-download="isShowDownload"
        @click="isAdd = true"
        @download="handleDownload"
        title="Добавить спортсмена"
      />
    </template>

    <b-table
      :data="isEmpty ? [] : athletes"
      :bordered="isBordered"
      :striped="isStriped"
      :narrowed="isNarrowed"
      :hoverable="isHoverable"
      :loading="isLoading"
      :focusable="isFocusable"
      :mobile-cards="hasMobileCards"
    >
      <b-table-column v-slot="props" label="#" width="40" numeric>
        {{ props.index + 1 }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="gender"
        label="Пол"
        sortable
        searchable
      >
        {{ props.row.gender }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="fullName"
        label="Фамилия, Имя, Отчество"
        sortable
        searchable
      >
        <router-link :to="getLink(props.row)" target="_blank">
          {{ props.row.fullName }}
        </router-link>
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="birthDate"
        label="Дата рождения"
        sortable
      >
        {{ props.row.birthDate | date }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Регион"
        field="region_ru.name"
        sortable
        searchable
      >
        {{ props.row.region_ru ? props.row.region_ru.name : "" }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Вид спорта"
        field="category_sport.Title"
        sortable
        searchable
      >
        {{ props.row.category_sport ? props.row.category_sport.Title : "" }}
      </b-table-column>
      <b-table-column v-if="isEditor" v-slot="props" label="">
        <div class="buttons">
          <b-button
            type="is-danger"
            icon-left="delete"
            size="is-small"
            @click="handleDelete(props.row.id)"
          />
        </div>
      </b-table-column>

      <template #empty>
        <div class="">Нет записей</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { Resource } from "@/common/const/common";
import { AppRoute } from "@/common/const/route";
import { mapGetters } from "vuex";
import AddButton from "@/common/components/AddButton";
import AddTeamMember from "./AddTeamMember";
import {
  ADD_ERROR,
  ADD_SUCCESS,
  DELETE_ERROR,
  DELETE_SUCCESS,
} from "@/common/const/message";

export default {
  name: "Team",
  components: { AddTeamMember, AddButton },
  props: {
    sportEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      athletes: [],
      isEmpty: true,
      isBordered: false,
      isStriped: false,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: false,

      isAdd: false,
      ids: [],
    };
  },
  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
    isShowDownload() {
      return !!this.athletes.length;
    },
  },
  async mounted() {
    await this.loadAthletes();
  },
  methods: {
    async handleDownload() {
      try {
        const uri =
          process.env.VUE_APP_API_URL +
          `/events/download-athletes/${this.sportEvent.id}`;
        const jwt = this.$jwt.getToken();
        const response = await fetch(uri, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const data = await response.blob();
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        // a.style.display = "none";
        link.href = url;
        link.download = "athletes.xlsx";
        // document.body.appendChild(a);
        link.click();
        window.URL.revokeObjectURL(url);
      } catch (e) {
        console.log(e);
      }
    },
    async handleAdd(payload) {
      this.isAdd = false;
      if (payload?.id) {
        const ids = [...this.ids, payload.id];
        await this.updatePeoples(ids, ADD_SUCCESS, ADD_ERROR);
      }
    },
    async handleDelete(id) {
      this.$buefy.dialog.confirm({
        title: "Удаление члена команды",
        class: "is-size-4",
        message: "Вы действительно хотите удалить?",
        cancelText: "Отмена",
        confirmText: "Да",
        hasIcon: true,
        type: "is-danger",
        onConfirm: async () => {
          const ids = this.ids.filter((value) => value !== id);
          await this.updatePeoples(ids, DELETE_SUCCESS, DELETE_ERROR);
        },
      });
    },
    async updatePeoples(ids, successMessage, errorMessage) {
      try {
        await this.$api[Resource.SPORT_EVENTS].put(
          { people: ids },
          this.sportEvent.id
        );
        await this.loadAthletes();
        this.$notifier.success(successMessage);
      } catch (e) {
        this.$notifier.error(errorMessage);
      }
    },
    async loadAthletes() {
      try {
        this.athletes = await this.$api[Resource.SPORT_EVENTS].get(
          `find-athletes/${this.sportEvent.id}`
        );
        if (this.athletes.length) {
          this.isEmpty = false;
        }
        this.ids = [];
        this.athletes.forEach(({ id }) => this.ids.push(id));
      } catch (e) {
        // eslint-disable-next-line
        // console.log(e);
      }
    },
    getLink({ id }) {
      return `${AppRoute.ATHLETES}/${id}`;
    },
  },
};
</script>
