<template>
  <ValidationObserver
    ref="form"
    v-slot="{ validate, invalid }"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Информация</p>
      </header>

      <section class="modal-card-body">
        <ValidationProvider
          v-for="(description, index) in edited"
          :key="description.id"
          tag="div"
          class="column px-0"
          rules="min:10"
          name="Описание"
        >
          <b-field
            slot-scope="{ errors, valid }"
            label="Описание"
            :message="errors"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
          >
            <v-md-editor
              v-model="edited[index].Text"
              height="600px"
            ></v-md-editor>
          </b-field>
        </ValidationProvider>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-submit-disabled="invalid"
          :is-show-reset="false"
          @send="validate().then(handleSubmit)"
          @close="handleClose"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash/lang";
import AppFormButtons from "@/common/components/AppFormButtons";

export default {
  name: "SportEventInfoForm",
  components: {
    AppFormButtons,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    sportEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      edited: {},
      width: 1800,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      sports: "sports/getSports",
    }),
  },
  created() {
    this.edited = cloneDeep(this.sportEvent.BodyElements);
    if (!this.edited?.length) {
      this.edited = [{ Text: "" }];
    }
  },
  beforeDestroy() {
    this.edited = null;
  },
  methods: {
    async handleSubmit() {
      this.$emit("close", this.edited);
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>
