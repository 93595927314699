var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Программа соревнований")])]),_c('section',{staticClass:"modal-card-body"},[_vm._l((_vm.edited),function(ref,index){
var id = ref.id;
var date = ref.date;
var text = ref.text;
return _c('CompetitionProgramFormItem',{key:id,attrs:{"id":id,"date":date,"text":text,"min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"setDate":function (value) { return (_vm.edited[index].date = value); },"editText":function (value) { return (_vm.edited[index].text = value); },"dropItem":_vm.handleDropItem}})}),_c('AddButton',{attrs:{"is-icon":true},on:{"click":_vm.handleAddItem}})],2),_c('footer',{staticClass:"modal-card-foot"},[_c('AppFormButtons',{attrs:{"is-submit-disabled":invalid,"is-show-reset":false},on:{"send":function($event){validate().then(_vm.handleSubmit)},"close":_vm.handleClose}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }