<template>
  <div class="container">
    <div class="columns">
      <div class="column">
        <h1 class="title">
          <PublishButton
            :is-published="isPublished"
            @publish="publish"
            @unpublished="unpublished"
          />
          {{ sportEvent.Title }}
        </h1>

        <EditButtons
          :is-delete-disable="isDeleteDisabled"
          @edit="handleEdit"
          @remove="handleRemove"
        />

        <div class="content">
          <table class="table-description">
            <tr>
              <td>Виды спорта:</td>
              <td>{{ sports }}</td>
            </tr>
            <tr>
              <td>Место проведения:</td>
              <td>{{ sportEvent.Location }}</td>
            </tr>
            <tr>
              <td>Даты:</td>
              <td>{{ dates }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="column is-one-third-desktop">
        <b-image
          v-if="imageUrl"
          class="image"
          ratio="3by2"
          :src="getImgUrl(imageUrl)"
        />

        <figure v-else class="media-content">
          <p class="image">
            <b-skeleton width="350px" height="250px" />
          </p>
        </figure>
      </div>
    </div>

    <b-tabs v-if="isLoaded && !isEdit" v-model="activeTab" type="is-boxed">
      <b-tab-item label="Результаты">
        <SportResultsTable :sport-event="sportEvent" />
      </b-tab-item>

      <b-tab-item v-if="sportEvent.isOlympic" label="Медальный зачет">
        <MedalStanding :sport-event="sportEvent" />
      </b-tab-item>

      <b-tab-item label="Информация">
        <SportEventInfo :sport-event="sportEvent" @edit="handleEditInfo" />
      </b-tab-item>

      <b-tab-item label="Состав команды">
        <Team :sport-event="sportEvent" />
      </b-tab-item>

      <b-tab-item label="Программа соревнований">
        <CompetitionProgram
          :sport-event="sportEvent"
          @edit="handleEditProgram"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import breadcrumbs from "@/common/mixins/breadcrumbs";
import { Resource } from "@/common/const/common";
import EditButtons from "@/common/components/AppEditButtons";
import SportEventForm from "./SportEventForm";
import SportResultsTable from "./SportResultsTable";
import Team from "./Team";
import PublishButton from "@/common/components/AppPublishButton";
import publish from "@/common/mixins/publish";
import {
  CANCEL_TEXT,
  CONFIRM_TEXT,
  DELETE_ERROR,
  DELETE_NOTICE,
  DELETE_SUCCESS,
  HIDE_NOTICE,
  HIDE_TITLE,
  SAVE_ERROR,
  SAVE_SUCCESS,
  UNKNOWN_ERROR,
} from "@/common/const/message";
import { AppRoute } from "@/common/const/route";
import { getImgUrl } from "@/common/utils/common";
import CompetitionProgram from "@/modules/calendar/components/CompetitionProgram";
import SportEventInfo from "@/modules/calendar/components/SportEventInfo";
import MedalStanding from "@/modules/calendar/components/MedalStanding";

export default {
  name: "SportEventShow",
  components: {
    MedalStanding,
    SportEventInfo,
    CompetitionProgram,
    PublishButton,
    Team,
    SportResultsTable,
    EditButtons,
  },
  mixins: [breadcrumbs, publish],
  data() {
    return {
      isEdit: false,
      sportEvent: {},
      results: [],
      isLoaded: false,
      activeTab: 0,
    };
  },
  computed: {
    dates() {
      if (!this.sportEvent) {
        return "";
      }
      const start = this.sportEvent.DateStart
        ? new Date(this.sportEvent.DateStart).toLocaleDateString()
        : "";
      const finish = this.sportEvent.DateFinish
        ? new Date(this.sportEvent.DateFinish).toLocaleDateString()
        : "";
      return `${start} - ${finish}`;
    },
    sports() {
      return this.sportEvent?.category_sports?.length
        ? this.sportEvent.category_sports
            .map(({ Title }) => Title.trim())
            .join(", ")
        : "*";
    },
    isPublished() {
      return !!this.sportEvent?.published_at || false;
    },
    isDeleteDisabled() {
      return true;
      // return !this.sportEvent.createdBy;
    },
    imageUrl() {
      return this.sportEvent.Images?.[0] || null;
    },
  },
  async mounted() {
    try {
      const { id } = this.$route.params;
      this.sportEvent = await this.$api[Resource.SPORT_EVENTS].getOne(
        id,
        "find-one"
      );
      if (!this.sportEvent) {
        await this.$router.push(AppRoute.NOT_FOUND);
        return;
      }
      this.isLoaded = true;

      this.$setBreadcrumbTitle(this.sportEvent.Title);
      document.title = this.sportEvent.Title;
    } catch (e) {
      // console.log(e);
    }
  },
  methods: {
    async publish() {
      this.sportEvent = await this.$publish(
        this.sportEvent.id,
        Resource.SPORT_EVENTS
      );
    },
    async unpublished() {
      this.$buefy.dialog.confirm({
        title: HIDE_TITLE,
        class: "is-size-4",
        message: HIDE_NOTICE,
        cancelText: CANCEL_TEXT,
        confirmText: CONFIRM_TEXT,
        hasIcon: true,
        type: "is-danger",
        onConfirm: async () => {
          try {
            this.sportEvent = await this.$unpublished(
              this.sportEvent.id,
              Resource.SPORT_EVENTS
            );
          } catch (e) {
            this.$notifier.error(UNKNOWN_ERROR);
          }
        },
      });
    },
    async handleRemove() {
      this.$buefy.dialog.confirm({
        title: "Удаление события",
        class: "is-size-4",
        message: DELETE_NOTICE,
        cancelText: CANCEL_TEXT,
        confirmText: CONFIRM_TEXT,
        hasIcon: true,
        type: "is-danger",
        onConfirm: async () => {
          try {
            await this.$api[Resource.SPORT_EVENTS].delete(this.sportEvent.id);
            this.$notifier.success(DELETE_SUCCESS);
            await this.$router.push({ name: "CalendarIndex" });
          } catch (e) {
            this.$notifier.error(DELETE_ERROR);
          }
        },
      });
    },
    async handleSave(sportEvent) {
      if (sportEvent) {
        this.sportEvent = sportEvent;
        this.$setBreadcrumbTitle(this.sportEvent.Title);
      }
    },
    async handleEdit() {
      this.$buefy.modal.open({
        parent: this,
        component: SportEventForm,
        props: { sportEvent: this.sportEvent },
        events: { close: this.handleSave },
        hasModalCard: true,
        fullScreen: true,
      });
    },
    async handleEditInfo(elements) {
      try {
        this.sportEvent = await this.$api[Resource.SPORT_EVENTS].put(
          { BodyElements: elements },
          this.sportEvent.id
        );
        this.$notifier.success(SAVE_SUCCESS);
      } catch (e) {
        this.$notifier.error(SAVE_ERROR);
      }
    },
    async handleEditProgram(program) {
      try {
        this.sportEvent = await this.$api[Resource.SPORT_EVENTS].put(
          { competitionProgram: program },
          this.sportEvent.id
        );
        this.$notifier.success(SAVE_SUCCESS);
      } catch (e) {
        this.$notifier.error(SAVE_ERROR);
      }
    },
    getImgUrl,
  },
};
</script>

<style>
.table-description {
  width: auto !important;
  border: 0 !important;
}
.table-description td:first-of-type {
  font-weight: bold;
}
</style>
