<template>
  <ValidationObserver
    v-slot="{ validate, invalid }"
    ref="form"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ edited.id ? "Редактировать " : "Новый " }}результат
        </p>
      </header>

      <b-loading
        :is-full-page="false"
        v-model="isDataLoading"
        :can-cancel="false"
      ></b-loading>

      <section class="modal-card-body">
        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="required"
          name="Вид спорта"
          slim
        >
          <b-field
            label="Вид спорта"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors[0]"
          >
            <template v-if="isSport">
              <b-input
                v-model="edited.category_sport.Title"
                type="text"
                placehoder="Выбрать вид спорта"
                disabled=""
              />
            </template>
            <template v-else>
              <b-select
                v-model="edited.category_sport"
                placehoder="Выбрать вид спорта"
                @change.native="loadDisciplines"
              >
                <option v-for="sport in sports" :key="sport.id" :value="sport">
                  {{ sport.Title }}
                </option>
              </b-select>
            </template>
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="required"
          name="Дисциплина"
          slim
        >
          <b-field
            label="Дисциплина"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors[0]"
          >
            <b-select
              v-model="edited.sport_discipline.id"
              placeholder="Выбрать дисциплину"
              :disabled="!edited.category_sport.id"
              @input="handleSelectDiscipline"
            >
              <option
                v-for="discipline in disciplines"
                :key="discipline.id"
                :value="discipline.id"
              >
                {{ printDiscipline(discipline) }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>

        <!--        <ValidationProvider-->
        <!--          v-if="!isWinter && isShowRound"-->
        <!--          rules="min:3"-->
        <!--          name="Раунд"-->
        <!--          slim-->
        <!--        >-->
        <!--          <b-field-->
        <!--            slot-scope="{ errors, valid }"-->
        <!--            label="Раунд"-->
        <!--            :type="{ 'is-danger': errors[0], 'is-success': valid }"-->
        <!--            :message="errors"-->
        <!--          >-->
        <!--            <b-input-->
        <!--              v-model="edited.round"-->
        <!--              type="text"-->
        <!--              placeholder="Раунд, необязательно"-->
        <!--            />-->
        <!--          </b-field>-->
        <!--        </ValidationProvider>-->

        <ValidationProvider
          v-if="isShowRound"
          v-slot="{ errors, valid }"
          name="Раунд"
          slim
        >
          <b-field
            label="Раунд"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors[0]"
          >
            <b-select placeholder="Выбрать раунд" v-model="edited.round">
              <option
                v-for="option in rounds"
                :value="option.name"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="required"
          name="Дата"
          vid="date"
          slim
        >
          <b-field
            label="Дата"
            :message="errors[0]"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
          >
            <b-datepicker
              v-model="date"
              placeholder="Выберите дату"
              icon="calendar-today"
              :locale="locale"
              :min-date="minDate"
              :max-date="maxDate"
              :focused-date="focusedDate"
            />
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="min_value:1"
          name="Место"
          slim
        >
          <b-field
            label="Место"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors[0]"
            style="width: auto"
          >
            <b-input v-model="edited.place" type="number" min="1" />
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          rules="alpha_spaces"
          name="Спортсмен"
          vid="athlete"
          slim
        >
          <b-field label="Спортсмены">
            <b-autocomplete
              clearable
              clear-on-select
              :disabled="!edited.sport_discipline.id"
              :data="dataAthletes"
              placeholder="Иванов"
              field="fullName"
              :loading="isFetching"
              @typing="findAthlete"
              @select="selectAthlete"
            >
              <template v-slot="props">
                <div class="media-content">
                  {{ props.option.fullName }}
                  <br />
                </div>
              </template>
            </b-autocomplete>
          </b-field>
        </ValidationProvider>
        <section v-if="selectedAthletes.length" class="mt-2 mb-4">
          <b-field v-for="a in selectedAthletes" :key="a.id">
            <b-tag
              attached
              closable
              aria-close-label="Close tag"
              @close="dropAthlete(a)"
            >
              {{ a.fullName }}
            </b-tag>
          </b-field>
        </section>

        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="min:3"
          name="Описание"
          slim
        >
          <b-field
            label="Описание"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors[0]"
          >
            <b-input
              v-model="edited.description"
              type="text"
              placeholder="Команда, описание"
            />
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, valid }"
          rules=""
          name="Результат"
          slim
        >
          <b-field
            label="Результат"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors[0]"
          >
            <b-input
              v-model="edited.result"
              type="text"
              placeholder="Результат"
            />
          </b-field>
        </ValidationProvider>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-loading="isLoading"
          :is-show-reset="false"
          :is-submit-disabled="invalid"
          @send="validate().then(handleSubmit)"
          @close="$emit('close')"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { DATE_FORMAT, LOCALE, Resource } from "@/common/const/common";
import {
  NETWORK_ERROR,
  SAVE_ERROR,
  SAVE_SUCCESS,
} from "@/common/const/message";
import { cloneDeep, debounce } from "lodash";
import AppFormButtons from "@/common/components/AppFormButtons";
import deleteTimestamps from "@/common/mixins/delete-timestamps";
import {
  hockeyRounds,
  SHORT_TRACK_TITLE,
  SKATING_TITLE,
  summerRounds,
} from "@/modules/calendar/const";

export default {
  name: "SportResultForm",
  components: { AppFormButtons, ValidationObserver, ValidationProvider },
  mixins: [deleteTimestamps],
  props: {
    sportEvent: {
      type: Object,
      required: true,
    },
    sportResult: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataAthletes: [],
      isFetching: false,
      selectedAthletes: [],

      locale: LOCALE,
      disciplines: [],
      date: null,
      edited: {},
      sports: [],

      minDate: null,
      maxDate: null,
      isLoading: false,
      isDataLoading: false,

      gender: null,
      isShowRound: false,
      rounds: [],
    };
  },
  computed: {
    ...mapGetters({
      isWinter: "auth/isWinter",
    }),
    // rounds() {
    //   return this.edited.category_sport?.isTeam ? hockeyRounds : [];
    // },
    isSport() {
      return this.sportEvent.category_sports?.length === 1;
    },
    focusedDate() {
      return this.sportEvent.DateStart
        ? new Date(this.sportEvent.DateStart)
        : new Date();
    },
  },
  created() {
    this.rounds = this.isWinter ? hockeyRounds : summerRounds;

    this.edited = cloneDeep(this.sportResult);
    this.$deleteTimestamps(this.edited);
    this.edited.event = this.sportResult.event || this.sportEvent.id;

    if (!this.edited.category_sport.id && this.isSport) {
      this.edited.category_sport = this.sportEvent.category_sports[0];
    }

    this.selectedAthletes = cloneDeep(this.sportResult.athletes);

    this.gender = this.edited.sport_discipline?.gender || null;

    this.date = this.sportResult.date
      ? this.$dayjs(this.sportResult.date).toDate()
      : null;

    this.minDate = this.sportEvent.isOlympic
      ? this.$dayjs(this.sportEvent.DateStart).subtract(2, "days").toDate()
      : this.$dayjs(this.sportEvent.DateStart).toDate();
    this.maxDate = this.$dayjs(this.sportEvent.DateFinish).toDate();
  },

  async mounted() {
    if (!this.isSport) {
      const { category_sports } = this.sportEvent;
      if (!category_sports?.length) {
        this.sports = await this.$api[Resource.SPORTS].get(`?_sort=Title:asc`);
      } else {
        this.sports = category_sports;
      }
    }

    await this.loadDisciplines();
  },

  async beforeDestroy() {
    this.edited = null;
    this.selectedAthletes = null;
  },

  methods: {
    /**
     * Настроить Validation или описание или спортсмен!!!!
     * @returns {Promise<void>}
     */
    async handleSubmit() {
      try {
        this.isLoading = true;

        const edited = { ...this.edited };
        edited.date = this.$dayjs(this.date).format(DATE_FORMAT);
        edited.category_sport = this.edited.category_sport.id;
        edited.sport_discipline = this.edited.sport_discipline.id;
        edited.athletes = this.selectedAthletes.map(({ id }) => id);
        edited.place = edited.place >= 1 ? edited.place : null;

        if (!edited.athletes.length && !this.edited.description) {
          this.$notifier.error("Нет описания или не выбран спортсмен");
          return;
        }

        if (this.edited.id) {
          await this.$api[Resource.SPORT_RESULTS].put(edited, edited.id);
        } else {
          await this.$api[Resource.SPORT_RESULTS].post(edited);
        }

        this.$notifier.success(SAVE_SUCCESS);
        await this.$emit("close", true);
      } catch (e) {
        this.$notifier.error(SAVE_ERROR);
      } finally {
        this.isLoading = false;
      }
    },

    selectAthlete(athlete) {
      if (athlete && athlete.id) {
        this.selectedAthletes.push(athlete);
      }
    },

    dropAthlete({ id }) {
      this.selectedAthletes = this.selectedAthletes.filter(
        (item) => item.id !== id
      );
    },

    findAthlete: debounce(async function (name) {
      if (name?.length <= 2) {
        this.dataAthletes = [];
        return;
      }
      this.isFetching = true;

      try {
        const ids = this.selectedAthletes.length
          ? this.selectedAthletes.map(({ id }) => `id_nin=${id}`)
          : [];

        // выбрать пол из дисциплины
        // handleSelectDiscipline сохранил пол
        const queryGender = this.gender ? `gender=${this.gender}` : "";

        // костыль для шорт-трек
        const sportTitle = this.edited?.category_sport?.Title;
        let categorySport = "?category_sport";
        if (sportTitle === SKATING_TITLE || sportTitle === SHORT_TRACK_TITLE) {
          categorySport = `${categorySport}.Title_in=${SKATING_TITLE}&category_sport.Title_in=${SHORT_TRACK_TITLE}`;
        } else {
          categorySport = `${categorySport}=${this.edited.category_sport.id}`;
        }

        const query = [
          categorySport,
          `lastname_contains=${name}`,
          ...ids,
          queryGender,
        ].join("&");

        this.dataAthletes = await this.$api[Resource.PEOPLES].get(query);
      } catch (e) {
        this.dataAthletes = [];
      } finally {
        this.isFetching = false;
      }
    }, 500),

    async loadDisciplines() {
      this.isShowRound = this.isWinter
        ? this.edited.category_sport?.isTeam
        : true;

      if (this.edited.category_sport?.id) {
        try {
          this.isDataLoading = true;
          this.disciplines = await this.$api[Resource.SPORT_DISCIPLINES].get(
            `find-by-sport/${this.edited.category_sport.id}`
          );
          this.disciplines.sort(
            (a, b) =>
              a.name.localeCompare(b.name) || a.gender?.localeCompare(b.gender)
          );
        } catch (e) {
          this.$notifier.error(NETWORK_ERROR);
          console.log(e);
        } finally {
          this.isDataLoading = false;
        }
      }
    },

    printDiscipline(discipline) {
      const name = discipline.name || null;
      const gender = discipline.gender?.trim().length
        ? discipline.gender
        : null;
      return name && gender ? `${name}, ${gender}` : name ? name : gender;
    },

    handleSelectDiscipline(value) {
      const { gender } =
        this.disciplines.find(({ id }) => id === value) || null;

      this.gender = gender;
    },
  },
};
</script>
