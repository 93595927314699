<template>
  <ValidationObserver
    ref="form"
    v-slot="{ validate, invalid }"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Программа соревнований</p>
      </header>

      <section class="modal-card-body">
        <CompetitionProgramFormItem
          v-for="({ id, date, text }, index) in edited"
          :key="id"
          :id="id"
          :date="date"
          :text="text"
          :min-date="minDate"
          :max-date="maxDate"
          @setDate="(value) => (edited[index].date = value)"
          @editText="(value) => (edited[index].text = value)"
          @dropItem="handleDropItem"
        />

        <AddButton :is-icon="true" @click="handleAddItem" />
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-submit-disabled="invalid"
          :is-show-reset="false"
          @send="validate().then(handleSubmit)"
          @close="handleClose"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AppFormButtons from "@/common/components/AppFormButtons";
import { DATE_FORMAT } from "@/common/const/common";
import CompetitionProgramFormItem from "@/modules/calendar/components/CompetitionProgramFormItem";
import { mapGetters } from "vuex";
import AddButton from "@/common/components/AddButton";

export default {
  name: "CompetitionProgramForm",
  components: {
    AddButton,
    CompetitionProgramFormItem,
    AppFormButtons,
    ValidationObserver,
  },
  props: {
    program: {
      type: Array,
      default: () => [],
    },
    sportEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      edited: [],
      minDate: null,
      maxDate: null,
    };
  },

  created() {
    const program = this.program.length
      ? [...this.program]
      : [{ date: this.sportEvent.DateStart, items: [""] }];

    this.edited = program.map(({ date, items }, index) => {
      return {
        id: `${date}-${index}`,
        date: new Date(date),
        text: items.join("\n"),
      };
    });

    this.minDate = this.$dayjs(this.sportEvent.DateStart).toDate();
    this.maxDate = this.$dayjs(this.sportEvent.DateFinish).toDate();
  },

  beforeDestroy() {
    this.edited = null;
  },

  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
  },

  methods: {
    handleAddItem() {
      const index = this.edited.length;
      let lastDate;
      if (index === 1) {
        lastDate = this.edited[0].date;
      } else {
        [lastDate] = this.edited
          .map(({ date }) => date)
          .sort((a, b) => a - b)
          .slice(this.edited.length - 1);
      }

      let newDate = this.$dayjs(lastDate).add(1, "day").toDate();
      if (newDate > this.maxDate) {
        newDate = this.maxDate;
      }

      const dateString = this.$dayjs(newDate).format(DATE_FORMAT);
      // const date = this.$dayjs(this.minDate).format(DATE_FORMAT);
      const id = `${dateString}-${index}`;

      this.edited = [...this.edited, { id, date: newDate, text: "" }];
    },
    handleDropItem(id) {
      this.edited = this.edited.filter((item) => item.id !== id);
    },
    handleSubmit() {
      const program = this.edited
        .sort((a, b) => a.date - b.date)
        .filter(({ date }) => null !== date)
        .map(({ date, text }) => {
          return {
            date: this.$dayjs(date).format(DATE_FORMAT),
            items: text.split("\n").filter((item) => !!item.trim()),
          };
        });
      this.$emit("close", program);
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>
