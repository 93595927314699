<template>
  <div class="content">
    <AddButton v-if="isShowAdd" :is-icon="true" @click="handleClickEdit" />

    <div v-else-if="isEditor" class="buttons">
      <b-button type="is-info" icon-right="pencil" @click="handleClickEdit" />
    </div>

    <div
      v-for="({ date, items }, index) in sportEvent.competitionProgram"
      :key="`${date}-${index}`"
    >
      <p class="title is-size-6 mb-0">{{ date | date }}</p>
      <ul style="margin-top: 0.5rem !important; margin-bottom: 2rem">
        <li v-for="(item, index) in items" :key="`program-item-${index}`">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AddButton from "@/common/components/AddButton";
import CompetitionProgramForm from "@/modules/calendar/components/CompetitionProgramForm";
import { mapGetters } from "vuex";

export default {
  name: "CompetitionProgram",
  components: { AddButton },
  props: {
    sportEvent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
    isShowAdd() {
      return (
        !this.sportEvent.competitionProgram ||
        !this.sportEvent.competitionProgram.length
      );
    },
  },
  methods: {
    handleClickEdit() {
      this.$buefy.modal.open({
        parent: this,
        component: CompetitionProgramForm,
        props: {
          sportEvent: this.sportEvent,
          program: this.sportEvent.competitionProgram,
        },
        events: { close: this.handleClose },
        hasModalCard: true,
      });
    },
    handleClose(program) {
      if (program) {
        this.$emit("edit", program);
      }
    },
  },
};
</script>
