<template>
  <b-table
    :data="countries"
    :loading="isLoading"
    :mobile-cards="false"
    :default-sort-direction="'asc'"
    default-sort="teamPlace"
    :row-class="getRowClass"
  >
    <b-table-column v-slot="props" label="№">
      {{ props.index + 1 }}
    </b-table-column>
    <b-table-column v-slot="props" label="Страна" sortable field="country.name">
      {{ props.row.country.name }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Золото"
      numeric
      centered
      sortable
      :custom-sort="sortFirstPlace"
    >
      {{ props.row.firstPlace }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Серебро"
      numeric
      centered
      sortable
      :custom-sort="sortSecondPlace"
    >
      {{ props.row.secondPlace }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Бронза"
      numeric
      centered
      sortable
      :custom-sort="sortThirdPlace"
    >
      {{ props.row.thirdPlace }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Командное место"
      numeric
      centered
      sortable
      :custom-sort="sortTeamPlace"
    >
      <span class="has-text-weight-bold">{{ props.row.teamPlace }}</span>
    </b-table-column>
  </b-table>
</template>

<script>
import {
  getRowClass,
  sortFirstPlace,
  sortSecondPlace,
  sortTeamPlace,
  sortThirdPlace,
} from "@/modules/calendar/utils";

export default {
  name: "MedalStandingCountriesTable",

  props: {
    countries: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    getRowClass,
    sortFirstPlace,
    sortSecondPlace,
    sortThirdPlace,
    sortTeamPlace,
  },
};
</script>
