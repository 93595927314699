<template>
  <div class="program-form-item">
    <span
      class="icon is-right is-clickable drop"
      @click="$emit('dropItem', id)"
    >
      <i class="mdi mdi-close-circle mdi-24px" />
    </span>
    <ValidationProvider
      v-slot="{ errors, valid }"
      rules="required"
      name="Дата"
      slim
    >
      <b-field
        label="Дата"
        :message="errors[0]"
        :type="{ 'is-danger': errors[0], 'is-success': valid }"
      >
        <b-datepicker
          v-model="editDate"
          placeholder="Выберите дату"
          icon="calendar-today"
          locale="ru-Ru"
          :icon-right="editDate ? 'close-circle' : ''"
          icon-right-clickable
          @icon-right-click="editDate = null"
          :min-date="minDate"
          :max-date="maxDate"
        >
          >
        </b-datepicker>
      </b-field>
    </ValidationProvider>

    <ValidationProvider
      v-slot="{ errors, valid }"
      rules="min:5"
      name="Описание"
      slim
    >
      <b-field
        label="Описание"
        :message="errors[0]"
        :type="{ 'is-danger': errors[0], 'is-success': valid }"
      >
        <b-input
          v-model="editText"
          placeholder="Описание"
          type="textarea"
          :rows="4"
        />
      </b-field>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "CompetitionProgramFormItem",
  components: { ValidationProvider },
  props: {
    id: {
      type: String,
      required: true,
    },
    date: {
      type: Date,
      default: null,
    },
    text: {
      type: String,
      default: "",
    },
    minDate: {
      type: Date,
      required: true,
    },
    maxDate: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      editDate: new Date(),
      editText: "",
    };
  },
  created() {
    this.editDate = this.date;
    this.editText = this.text;
  },
  watch: {
    editDate() {
      this.$emit("setDate", this.editDate);
    },
    editText() {
      this.$emit("editText", this.editText);
    },
  },
};
</script>

<style scoped>
.program-form-item {
  border-radius: 5px;
  border: 1px solid gray;
  padding: 2rem 0.5rem 0.5rem;
  margin-bottom: 1rem;
  position: relative;
}

.drop {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
</style>
