<template>
  <div class="content">
    <AddButton v-if="isShowAdd" :is-icon="true" @click="handleClickEdit" />

    <div v-else-if="isEditor" class="buttons">
      <b-tooltip :label="addTitle">
        <b-button type="is-info" icon-right="pencil" @click="handleClickEdit" />
      </b-tooltip>
    </div>

    <template v-if="sportEvent.BodyElements">
      <v-md-preview
        v-for="element in sportEvent.BodyElements"
        :key="element.id"
        :text="element.Text"
      />
    </template>
  </div>
</template>

<script>
import AddButton from "@/common/components/AddButton";
import { mapGetters } from "vuex";
import SportEventInfoForm from "@/modules/calendar/components/SportEventInfoForm";

export default {
  name: "SportEventInfo",
  components: { AddButton },
  props: {
    sportEvent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
    addTitle() {
      return this.sportEvent.BodyElements.length
        ? "Редактировать"
        : "Добавить информацию";
    },
    isShowAdd() {
      return !this.sportEvent.BodyElements?.length;
    },
  },
  methods: {
    handleClickEdit() {
      this.$buefy.modal.open({
        parent: this,
        component: SportEventInfoForm,
        props: {
          sportEvent: this.sportEvent,
        },
        events: { close: this.handleClose },
        hasModalCard: true,
        fullScreen: true,
      });
    },
    handleClose(elements) {
      if (elements?.length) {
        this.$emit("edit", elements);
      }
    },
  },
};
</script>
